<template>
  <b-card v-if="Object.keys(homeEdit).length" class="blog-edit-wrapper">
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <!--Category-->
        <b-col md="6">
          <b-form-group
            label="Alan seçiniz"
            label-for="blog-edit-title"
            class="mb-2"
          >
            <v-select
              v-model="categorySelected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="option"
            />
          </b-form-group>
        </b-col>
        <!--Yeni Ekle Button-->
        <b-col cols="6" class="mt-2 mr-0">
          <b-button
            v-if="addNew"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1 ml-1"
            v-b-modal.modal-addNew
            @click="clearData()"
          >
            Yeni Ekle
          </b-button>
        </b-col>
      </b-row>
      <!-- Cards -->
      <b-row v-if="isTabloItem" :key="listeYenile">
        <b-col v-for="(item, index) in TabloItems" :key="index" md="12">
          <b-card class="mb-2 cardStyle">
            <b-img
              v-if="item.image"
              :src="item.image"
              class="card-img-top imgStyle"
              alt="Card image cap"
            />
            <b-card-body>
              <div v-if="item.title">
                <b-card-title class="titleStyle"
                  >{{ item.title }}
                </b-card-title>
              </div>
              <div v-if="item.text">
                <b-card-text>
                  <div v-html="item.text"></div>
                </b-card-text>
              </div>
            </b-card-body>
            <b-card-footer>
              <div class="d-flex justify-content-end">
                <b-button
                  @click="editModal(item)"
                  v-b-modal.modal-edit
                  variant="gradient-success"
                  class="btn-icon mr-1"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                  v-if="addNew"
                  @click="deleteModal(item)"
                  v-b-modal.modal-danger
                  variant="gradient-danger"
                  class="btn-icon"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </div>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->

    <!-- Edit Modal-->
    <b-modal
      id="modal-edit"
      title="Düzenle"
      cancel-variant="outline-secondary"
      size="lg"
      centered
      hide-footer
    >
      <b-form>
        <b-form-group v-if="isTitle" label="Başlık" label-for="name">
          <b-form-input v-model="homeEdit.editTitle" />
        </b-form-group>
        <b-form-group
          v-if="isText"
          label="Metin"
          label-for="blog-content"
          class="mb-2"
        >
          <quill-editor
            v-model="homeEdit.editContent"
            id="blog-content"
            :options="snowOption"
          />
        </b-form-group>
        <b-form-group v-if="isImage" label="Resim" label-for="zip-code">
          <div class="border rounded p-2">
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <b-media-aside>
                <b-img
                  ref="refPreviewEl"
                  height="110"
                  width="170"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
              </b-media-aside>
              <b-media-body>
                <small class="text-muted"
                  >Required image resolution 1200x600, image size 10mb.</small
                >
                <b-card-text class="my-50">
                  <b-link id="blog-image-text">
                    C:\fakepath\{{ blogFile ? blogFile.name : "banner.jpg" }}
                  </b-link>
                </b-card-text>
                <div class="d-inline-block">
                  <b-form-file
                    ref="refInputEl"
                    v-model="blogFile"
                    accept=".jpg, .png, .gif"
                    placeholder="Choose file"
                    @input="inputImageRenderer1"
                  />
                </div>
              </b-media-body>
            </b-media>
          </div>
        </b-form-group>
        <b-form-group>
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-success float-right"
            class="mt-3"
            @click="updateBtn()"
          >
            Kaydet
          </b-button>
        </b-form-group>
      </b-form>
    </b-modal>
    <!-- Add New Modal -->
    <b-modal
      id="modal-addNew"
      title="Yeni Ekle"
      ok-only
      ok-title="Kaydet"
      centered
      @ok="saveButton()"
      size="lg"
    >
      <b-form>
        <b-form-group v-if="isTitle" label="Başlık" label-for="name">
          <b-form-input v-model="homeEdit.editTitle" />
        </b-form-group>
        <b-form-group
          v-if="isText"
          label="Metin"
          label-for="blog-content"
          class="mb-2"
        >
          <quill-editor
            v-model="homeEdit.editContent"
            id="blog-content"
            :options="snowOption"
          />
        </b-form-group>
        <b-form-group v-if="isImage" label="Resim" label-for="zip-code">
          <div class="border rounded p-2">
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <b-media-aside>
                <b-img
                  ref="refPreviewEl"
                  height="110"
                  width="170"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
              </b-media-aside>
              <b-media-body>
                <small class="text-muted"
                  >Required image resolution 1200x600, image size 10mb.</small
                >
                <b-card-text class="my-50">
                  <b-link id="blog-image-text">
                    C:\fakepath\{{ blogFile ? blogFile.name : "banner.jpg" }}
                  </b-link>
                </b-card-text>
                <div class="d-inline-block">
                  <b-form-file
                    ref="refInputEl"
                    v-model="blogFile"
                    accept=".jpg, .png, .gif"
                    placeholder="Choose file"
                    @input="inputImageRenderer1"
                  />
                </div>
              </b-media-body>
            </b-media>
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
    <!-- Delete Modal-->
    <b-modal
      id="modal-danger"
      ok-only
      ok-variant="danger"
      ok-title="Ok"
      no-close-on-backdrop
      modal-class="modal-danger"
      centered
      @ok="deleteData()"
    >
      <b-card-text class="mt-1">
        SİLMEK İSTEDİĞİNİZE EMİN MİSİNİZ?
      </b-card-text>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BCardText,
  BCardTitle,
  BForm,
  BMedia,
  BCardBody,
  BRow,
  BModal,
  BAvatar,
  BMediaBody,
  BCol,
  BCardFooter,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BMediaAside,
  BProgress,
  BBadge,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "../form-utils";
import { ref } from "@vue/composition-api";

import { heightTransition } from "@core/mixins/ui/transition";
import axios from "axios";
import PillVue from '@/views/kalite/Pill.vue';
import kaliteDetailVue from '@/views/kalite/kaliteDetail.vue';
import kaliteGuvenceVue from '@/views/kalite/kaliteGuvence.vue';

export default {
  components: {
    BCard,
    BTable,
    BModal,
    BCardTitle,
    BCardFooter,
    BCardText,
    BForm,
    BLink,
    BMediaAside,
    BImg,
    BCardBody,
    BRow,
    BCol,
    BAvatar,
    BMedia,
    BMediaBody,
    BButton,
    BFormGroup,
    BFormInput,
    BProgress,
    BBadge,
    BFormFile,
    vSelect,
    quillEditor,
 
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      TabloItems: [],

      categorySelected: null,
      selectedCardComponent: null,

      blogFile: null,
      siteCompId: null,
      isTabloItem: false,

      isTitle: true,
      isText: true,
      isImage: true,

      addNew: false,
      listeYenile:0,

      // categoryInfo: null,

      homeEdit: {
        id: null,
        editTitle: null,
        editContent: null,
        editImage: null,
      },

      option: [
        { id: 29, title: "Resim" },
        { id: 30, title: "Kalite" },
        { id: 31, title: "Kalite ve Güvence Politikası" },
      ],

      snowOption: {
        theme: "snow",
      },

    };
  },
  watch: {
    categorySelected(yeni, eski) {
      this.getAllData();
     // console.log(this.categorySelected);
      this.kapaliyaCevir();
      if (yeni.id === 29) {
        this.selectedCardComponent = PillVue;
        this.isTabloItem = true;
        this.isImage = true;
      }
      if (yeni.id === 30) {
        this.selectedCardComponent = kaliteDetailVue;
        this.isTabloItem = true;
        this.isTitle = true;
        this.isText = true;
      }
      if (yeni.id === 31) {
        this.selectedCardComponent = kaliteGuvenceVue;
        this.isTabloItem = true;
        this.isTitle = true;
        this.isText = true;
      }
   
    },
  },
  mounted() {
    this.siteCompId = this.$route.meta.siteCompId;
   
   // console.log("Bu sayfa için siteCompId meta bilgisi:", this.siteCompId);
  },

  created() {
  
    this.$router.beforeEach(async (to, from, next) => {
      const isAuthenticated = localStorage.getItem("token");

      if (
        to.matched.some((record) => record.meta.requiresAuth) &&
        !isAuthenticated
      ) {
        // Bu sayfa kimlik doğrulama gerektiriyor ve kullanıcı giriş yapmamışsa
        next("/login");
      } else {
        next();
      }
    });
  },



  setup() {
    const refInputEl = ref(null);
    const refPreviewEl = ref(null);
    const refInputEl2 = ref(null);
    const refPreviewEl2 = ref(null);

    const { inputImageRenderer: inputImageRenderer1 } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );
    const { inputImageRenderer: inputImageRenderer2 } = useInputImageRenderer(
      refInputEl2,
      (base64) => {
        refPreviewEl2.value.src = base64;
      }
    );

    return {
      refInputEl,
      refPreviewEl,
      refInputEl2,
      refPreviewEl2,
      inputImageRenderer1,
      inputImageRenderer2,
    };
  },

  methods: {
    kapaliyaCevir() {
      this.isImage = false;
      this.isText = false;
      this.isTitle = false;
      this.isTabloItem = false;
      this.addNew = false;
    },
    //tüm veriyi getir
    getAllData() {
      const languageCode = localStorage.getItem("language") === "2" ? 2 : 1;
      axios
        .get(
          `/api/Contents/GetListByComponentIdAndLanguage?id=${this.categorySelected.id}&language=${languageCode}`
        )
        .then((result) => {
          if (result.data.success) {
            this.TabloItems = result.data.data;
            //    console.log(this.TabloItems);
          } else {
            console.error("API yanıtında başarısızlık:", result.data.message);
          }
        })
        .catch((error) => {
          console.error("Get Catch'e düştü", error);
        });
    },
    //Create/Post
    saveButton() {
      let languageCode = localStorage.getItem("language") === "2" ? 2 : 1;
      const newSliderInfo = {
        title: this.homeEdit.editTitle,
        text: this.homeEdit.editContent,
        url: this.homeEdit.editImage,
        componentId: this.categorySelected.id,
        language: languageCode
      };
      // console.log(this.componentId, this.pageId);
      axios
        .post("/api/Contents/Create", newSliderInfo)
        .then((result) => {
          if (result.data.success) {
            this.$bvToast.toast("GÜNCELLENDİ", {
              title: `ANASAYFA`,
              variant: "success",
            });

            this.saveImg(result.data.data.id, this.blogFile); // kontrol et
          } else {
            this.$bvToast.toast("GÜNCELLENEMEDİ", {
              title: `ANASAYFA`,
              variant: "danger",
            });
          }
          setTimeout(() => {
            this.getAllData();
          }, 500);
        })
        .catch((error) => {
          console.error("Güncelleme Hatası", error);
        });
    },
    //Update/Post
    updateBtn() {
      const newSliderInfo = {
        id: this.homeEdit.id,
        title: this.homeEdit.editTitle,
        text: this.homeEdit.editContent,
        url: this.homeEdit.editImage,
      };

      axios
        .post("/api/Contents/Update", newSliderInfo,
        )
        .then((result) => {
          if (result.data.success) {
            this.$bvToast.toast("GÜNCELLENDİ", {
              title: `ANASAYFA`,
              variant: "success",
            });
            this.saveImg(result.data.data.id, this.blogFile); // kontrol et
          } else {
            this.$bvToast.toast("GÜNCELLENEMEDİ", {
              title: `ANASAYFA`,
              variant: "danger",
            });
          }
          this.getAllData();
        })
        .catch((error) => {
          console.error("Güncelleme Hatası", error);
        });
    },
    //Img Yükle
    saveImg(id, img) {
      const formData = new FormData();
      formData.append("file", img);
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      axios
        .post(
          `/api/Contents/UploadImage/${id}`,
          formData,
          { headers }
        )
        .then((response) => {
        //  console.log("saveImg:", response);
          this.blogFile = response.data.image;
        });
    },
    editModal(item) {
     // console.log(item);
      this.homeEdit.editTitle = item.title;
      this.homeEdit.editContent = item.text;
      this.homeEdit.blogFile = item.image;
      this.homeEdit.id = item.id;
    },
    deleteModal(item) {
      this.homeEdit.id = item.id;
    },
    //delete/Post
    deleteData() {
      axios
        .post( `/api/Contents/Delete/${this.homeEdit.id}`
        )
        .then(() => {
          this.getAllData();
        })
        .catch((error) => {
          console.error("Delete Hatası", error);
        });
    },
    clearData() {
      this.homeEdit.editTitle = null;
      this.homeEdit.editContent = null;
      this.homeEdit.editImage = null;
    },

  
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
.cardStyle {
  border: 1px solid grey;
  background-color: rgba(140, 235, 153, 0.13);
}
.imgStyle {
  max-height: "100px";
  max-width: "300px";
  object-fit: "cover";
}
.titleStyle {
  text-align: center;
}
</style>
